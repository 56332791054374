
import { dateFormatMixin } from '../../mixins'

export default {
  mixins: [dateFormatMixin],
  props: {
    publication: {
      type: Object,
      default: null,
    },
  },
  computed: {
    cardProps () {
      return {
        'content-type': this.contentType,
        image: this.image,
        imageSize: this.imageSize,
        subtitle: this.subtitle,
        subtitleBelow: true,
        title: this.title,
        titleSize: this.titleSize,
        to: this.to,
      }
    },
    image () {
      return this.publication && (this.publication.poster || this.publication.imageUrl || this.publication.hero)
    },
    imageSize () {
      return this.$attrs['image-size'] || 'xs:374x234 sm:608x380 md:565x353 lg:400x250'
    },
    isImageFull () {
      return this.$attrs['image-full'] || this.$attrs['image-full'] === ''
    },
    isSmall () {
      return this.$attrs.small || this.$attrs.small === ''
    },
    subtitle () {
      if (!this.publication) return null
      return this.publication.shortDescription || this.publication.kickerDescription || this.publication.subTitle
    },
    title () {
      return `Aflevering van ${this.dateFormat.past(this.publication?.sortDate)}`
    },
    titleSize () {
      return this.isSmall ? 'xs' : 'sm'
    },
    to () {
      if (!this.publication) return null
      return this.publication.mid ? `${this.$config.baseUrlNpoStart}/${this.publication.mid}` : null
    },
  },
}
